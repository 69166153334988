import './index.scss';

import { Button, Input, Message, Form } from '@arco-design/web-react';
import { useContext, useLayoutEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from '@modern-js/runtime/router';
import { IconLeft } from '@arco-design/web-react/icon';
import dayjs from 'dayjs';
import useLocale from '@/hooks/useLocale';
import { fetchForgotPwd } from '@/api/passport_api';
import SimpleHead from '@/components/simple-head';
import { validator } from '@/utils/validator';
import { ErrorCode } from '@/api/errno';
import { GlobalContext } from '@/routes/context';
import { useVisibilityChange } from '@/hooks/useVisibilityChange';
import { ForgotPasswordEvent, ModuleName, PageEvent, PageNameConfig } from '@/config/track.config';

const formItemLayout = {
  wrapperCol: {
    span: 24,
  },
};
const PasswordForgot = () => {
  const locale = useLocale();
  const navigate = useNavigate();
  const location = useLocation();
  const documentVisible = useVisibilityChange();

  const [form] = Form.useForm();
  const [confirmLogin, setConfirmLogin] = useState(false);
  const timeRef = useRef(dayjs().unix());
  const { collectEvent } = useContext(GlobalContext);

  const emailVal = Form.useWatch('email', form);

  const pathname = location.state?.from?.pathname;
  const fromUrl = pathname || '/login';
  const pageName = PageNameConfig.PWD_FORGOT;
  const fromLink = pathname ? fromUrl : '';

  useLayoutEffect(() => {
    if (documentVisible) {
      timeRef.current = dayjs().unix();
      collectEvent?.([
        {
          event: PageEvent.PAGE_SHOW,
          params: {
            fromLink,
            pageName,
          },
        },
      ]);
    } else {
      onUnMounted();
    }
  }, [documentVisible]);

  const onUnMounted = () => {
    collectEvent?.([
      {
        event: PageEvent.PAGE_HIDE,
        params: {
          fromLink,
          pageName,
          duration: `${dayjs().unix() - timeRef.current}`,
        },
      },
    ]);
  };

  // 提交表单
  const onSubmit = async (values: any) => {
    setConfirmLogin(() => true);
    try {
      await fetchForgotPwd({ ...values });
      Message.success(locale.pwd_forgot_message_success);
      collectEvent?.([
        {
          event: ForgotPasswordEvent.SEND_RESET_PASSWORD_LINK,
          params: {
            pageName,
            redirectUrl: fromUrl,
            moduleName: ModuleName.PWD_FORGOT_FORM,
          },
        },
      ]);
      navigate(fromUrl, { replace: true });
    } catch (error: any) {
      const errorCode = error.code;
      collectEvent?.([
        {
          event: ForgotPasswordEvent.SEND_RESET_PASSWORD_LINK,
          params: {
            pageName,
            errorCode,
            message: error.msg,
            status: 'fail',
            moduleName: ModuleName.PWD_FORGOT_FORM,
          },
        },
      ]);
      //   邮件已发送
      if (ErrorCode.H_ERR_SEND_EMAIL_LIMIT === errorCode) {
        Message.error(locale.pwd_forgot_err_send_email_limit);
        return;
      }
      //   用户不存在
      if (ErrorCode.H_ERR_USER_NOT_EXIST === errorCode) {
        Message.error(locale.pwd_forgot_submit_failed);
        return;
      }

      Message.error(`code: ${error.code}, msg:${error.msg}`);
    } finally {
      setConfirmLogin(() => false);
    }
  };

  const onBack = () => {
    collectEvent?.([
      {
        event: ForgotPasswordEvent.RESET_PASSWORD_RO_BACK,
        params: {
          pageName,
          redirectUrl: fromUrl,
          moduleName: ModuleName.PWD_FORGOT_FORM,
        },
      },
    ]);
    navigate(fromUrl, { replace: true });
  };

  return (
    <>
      <SimpleHead />
      <div className="page-body flex justify-center items-center  w-full">
        <div className="password-forgot flex flex-col">
          <IconLeft onClick={onBack} className="cancel-button" />
          <div className="password-forgot__title  text-center">{locale.pwd_forgot_title}</div>
          <div className="line"></div>
          <Form {...formItemLayout} form={form} size="large" autoComplete="off" onSubmit={onSubmit} className="password-forgot__body">
            <Form.Item
              field="email"
              rules={[
                {
                  type: 'email',
                  validator: validator.checkEmail,
                  message: locale.pwd_forgot_validator_email_error,
                },
              ]}
            >
              <Input placeholder={locale.pwd_forgot_input_text_placeholder} className="input-text" />
            </Form.Item>
            <Form.Item>
              <Button long type="primary" htmlType="submit" className="submit-btn" loading={confirmLogin} disabled={!emailVal}>
                {locale.pwd_forgot_input_submit}
              </Button>
            </Form.Item>
          </Form>
          <div>&nbsp;</div>
        </div>
      </div>
    </>
  );
};

export default PasswordForgot;
